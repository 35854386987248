<!-- PrivatePromptsMain.vue -->
<template>

  <!-- Banner mit Hintergrundfarbe -->
  <v-container fluid class="banner">
    <v-row class="d-flex align-center justify-space-between">
      <!-- Text zentriert im Banner -->
      <v-col cols="12" md="11" class="d-flex justify-center text-center">
        <v-img src="./logo.png" alt="Private Prompts Logo" contain max-width="150"></v-img>
        <div>
          <h3>
            Deine Daten gehören dir!
          </h3>
          <h1 class="font-weight-bold">
            Private Prompts
          </h1>
          <h4>
            KI und Webdienste sicher nutzen
          </h4>
        </div>
      </v-col>
    </v-row>
  </v-container>

  <!-- Video-Bereich -->
  <v-container class="video-section" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card outlined hover class="video-card">
          <!-- Schräger Banner oben rechts -->
          <div class="video-banner">Dieses Video<br />wurde mit Hilfe<br />von KI erstellt</div>
          <v-img src="./mvp-teaser-image.png" aspect-ratio="16/9"
            alt="Vorschaubild des Einführungsvideos zu Private Prompts">
            <!-- Play-Button in der Mitte -->
            <v-container fluid justify="center">
              <v-btn @click="playVideo">Video abspielen</v-btn>
            </v-container>
          </v-img>
          <v-card-text class="text-center">
            In diesem kurzen Video erfährst du, wie <span class="brand-name">Private Prompts</span> deine persönlichen
            Daten schützt,
            dir mehr Kontrolle über deine Privatsphäre gibt und wie du das Projekt aktiv unterstützen kannst.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <v-container fluid class="status-message">
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <h2>Gleich ausprobieren</h2>
      </v-col>
      <v-col cols="12" md="10" class="text-center">
        <p>Schütze deine sensiblen Informationen und nutze KI sicher und privat. Mit <span class="brand-name">Private
            Prompts</span> behältst du die Kontrolle über deine Daten.
          <span class="brand-name">Private Prompts</span> befindet sich noch in der Entwicklungsphase.
          Aber du kannst schon jetzt die Testversion für Windows und Linux ausprobieren!
        </p>
        <p><v-btn color="secondary" href="https://github.com/fboerncke/private-prompts-prototype/releases"
            target="_blank">
            <v-icon size="32">mdi-cloud-download-outline</v-icon>&nbsp;Downloadseite mit Release Notes</v-btn>
        </p>
        <p>Es wird in den kommenden Monaten regelmäßig neue Versionen geben. Du kannst die Updates
          ausprobieren und mit deinem Testfeedback und Wünschen dabei helfen, dass <span class="brand-name">Private
            Prompts</span>
          zu einer noch besseren Anwendung wird.</p>
      </v-col>
    </v-row>
  </v-container>

  <!-- Schnellstart Download -->
  <v-container fluid class="quickstart">
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <h2>Schnellstart</h2>
      </v-col>
      <v-col cols="12" md="10" class="text-center">
        <p>
          Du weißt, was du möchtest und magst nicht viel lesen? Dann kannst du eine Testversion von <span
            class="brand-name">Private
            Prompts</span> direkt hier herunterladen und dann ohne Installation starten:
        </p>
        <v-row>
          <v-col cols="3" class="d-flex align-center justify-end">
            <strong>Windows</strong>
          </v-col>
          <v-col cols="9" class="d-flex align-center justify-start">
            <v-btn color="secondary"
              href="https://github.com/fboerncke/private-prompts-prototype/releases/download/v0.001-MVP-002/PrivatePrompts-MVP-002.exe"
              target="_blank">
              <v-icon size="32">mdi-microsoft-windows</v-icon>&nbsp;PrivatePrompts-MVP-002.exe</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="d-flex align-center justify-end">
            <strong>Linux</strong>
          </v-col>
          <v-col cols="9" class="d-flex align-center justify-start">
            <v-btn color="secondary"
              href="https://github.com/fboerncke/private-prompts-prototype/releases/download/v0.001-MVP-002/PrivatePrompts-MVP-002.AppImage"
              target="_blank">
              <v-icon size="32">mdi-penguin</v-icon>&nbsp;PrivatePrompts-MVP-002.AppImage</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="d-flex align-center justify-end">
            <strong>MacOS</strong>
          </v-col>
          <v-col cols="9" class="d-flex align-center justify-start">
            <v-btn color="secondary" href="" target="">
              <v-icon size="32">mdi-apple</v-icon>&nbsp;noch nicht verfügbar</v-btn>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>

  <!-- Schritt-für-Schritt-Anleitung -->
  <v-container class="how-it-works" fluid>
    <v-row>
      <v-col cols="12" class="text-center">
        <h2>So funktioniert es</h2>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-cloud-download</v-icon>
        <h3>1. Download</h3>
        <p>
          Lade <span class="brand-name">Private Prompts</span> kostenlos herunter. Für Windows und Linux gibt es
          ausführbare Dateien, die einfach per Doppelklick gestartet werden können.
          Das funktioniert auf Wunsch auch vom USB-Stick aus.
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-power</v-icon>
        <h3>2. Einfach Starten</h3>
        <p>
          Starte die Anwendung durch Doppelklick. Eine Installation ist nicht erforderlich. Lediglich eine
          Konfigurationsdatei wird auf deinem Rechner gespeichert, sodass du Eingaben nicht erneut vornehmen musst.
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-fingerprint</v-icon>
        <h3>3. Mehr Sicherheit</h3>
        <p>
          Nutze KI sicher, privat und risikofrei. Du definierst nach deinen Vorstellungen, was für dich privat ist
          und welche Informationen
          besser
          auf deinem Rechner bleiben sollen, wenn du Dienste aus dem Internet verwendest.
        </p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-carousel height="auto" hide-delimiter-background>

          <!-- Slide 1 -->
          <v-carousel-item>
            <div class="caption">
              Sehen wir uns folgendes Beispiel an: John Doe möchte sich mit Hilfe einer KI einen Entwurf für ein
              Bewerbungsschreiben
              generieren lassen. Natürlich
              sind in dem Prompt für so eine Aufgabe viele persönliche Daten enthalten. John fühlt sich nicht wohl
              damit, dass private Informationen an KI-Dienste geschickt und möglicherweise zu Trainingszwecken verwendet
              werden. Was kann er tun?
            </div>
            <v-img src="./slides/01.png" alt="Prompt für Erstellung eines Bewerbungsschreibens"
              class="carousel-image"></v-img>
          </v-carousel-item>

          <!-- Slide 2 -->
          <v-carousel-item>
            <div class="caption">
              Mit dem Knopf "MARK" lässt John sich die Teile des Prompts farblich markieren, die von Private Prompts
              automatisch als sensitiv
              erkannt werden. John kann sich nun überlegen, ob er diese Informationen manuell aus dem Prompt
              löschen will. Er möchte aber lieber die Möglichkeit nutzen, die sensitiven Daten mit Platzhaltern zu
              maskieren. Dabei hilft
              ihm Private Prompts.</div>
            <v-img src="./slides/02.png" alt="Sensitive Inhalte werden maskiert" class="carousel-image"></v-img>
          </v-carousel-item>

          <!-- Slide 3 -->
          <v-carousel-item>
            <div class="caption">
              John entscheidet sich dafür, die sensitiven Inhalte von Private Prompts automatisch maskieren zu lassen.
              Dazu verwendet er den Knopf "MASK". "John Doe" wird dadurch zum Beispiel durch "Max Mustermann" ersetzt
              (Pseudonymisierung).
              Die Anwendung merkt sich diesen Vorgang, um die vorgenommenen Ersetzungen später wieder rückgängig machen
              zu können.
            </div>
            <v-img src="./slides/03.png" alt="Sicherer Umgang mit KI" class="carousel-image"></v-img>
          </v-carousel-item>

          <!-- Slide 4 -->
          <v-carousel-item>
            <div class="caption">
              Nun möchte John den pseudonymisierten Prompt ausführen. Er kann den geschützten Prompt mit Copy & Paste in
              einer
              AI seiner Wahl ausführen. Alternativ nutzt er heute die Funktion, den Prompt mit dem Button "SEND TO AI"
              direkt
              zu OpenAI zu schicken. Diese Option dient der Bequemlichkeit - man kann Private Prompts aber auch
              benutzen,
              ohne einen OpenAI-Key zu hinterlegen.
            </div>
            <v-img src="./slides/04.png" alt="Sicherer Umgang mit KI" class="carousel-image"></v-img>
          </v-carousel-item>

          <!-- Slide 5 -->
          <v-carousel-item>
            <div class="caption">
              John bearbeitet das Ergebnis der KI Verarbeitung mit der Funktion "UNMASK", um die Pseudonymisierung im
              Ergebnis wieder rückgängig zu
              machen. "Max Mustermann" wird so wieder zu "John Doe". John hat nun einen Entwurf für ein
              Bewerbungsschreiben mit Hilfe einer KI generieren lassen, aber die KI hat seinen Namen und die anderen
              persönlichen Daten nie gesehen!
            </div>
            <v-img src="./slides/05.png" alt="Sicherer Umgang mit KI" class="carousel-image"></v-img>
          </v-carousel-item>

          <!-- Slide 6 -->
          <v-carousel-item>
            <div class="caption">
              Woher weiß Private Prompts überhaupt, welche Informationen geschützt werden sollen? John nutzt dazu die
              Funktion ‚PRIVATE
              DATA DEFINITIONS‘, um festzulegen, welche Daten er vertraulich behandeln möchte. Hier könnte er auch eine
              zusätzliche
              Regel ergänzen, um zum Beispiel "Jane Doe" temporär mit dam Namen "Monika Musterfrau" zu pseudonymisieren.
            </div>
            <v-img src="./slides/06.png" alt="Sicherer Umgang mit KI" class="carousel-image"></v-img>
          </v-carousel-item>

        </v-carousel>
      </v-col>
    </v-row>

  </v-container>

  <!-- Vorteile auf einen Blick -->
  <v-container class="benefits  pl-9 pr-9" fluid>
    <v-row>
      <v-col cols="12" class="text-center">
        <h2>Deine Vorteile auf einen Blick</h2>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-check-bold</v-icon>
        <h3>Keine Cloud</h3>
        <p>
          Behalte die Kontrolle über deine sensiblen Daten. Mit <span class="brand-name">Private Prompts</span> bleiben
          alle Informationen
          sicher auf deinem eigenen Rechner. Keine unerwünschte Weitergabe, kein Risiko.
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-check-bold</v-icon>
        <h3>Intuitive Bedienung</h3>
        <p>
          Starte sofort, ohne technische Hürden und Vorkenntnisse. <span class="brand-name">Private Prompts</span> ist
          so gestaltet, dass du es ohne Vorkenntnisse nutzen kannst. Einfach herunterladen, starten und loslegen.
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-check-bold</v-icon>

        <h3>Du entscheidest</h3>
        <p>
          Bestimme selbst, welche Informationen privat bleiben sollen. Mit <span class="brand-name">Private
            Prompts</span> kannst du festlegen, welche Daten auf deinem Rechner bleiben und welche verarbeitet werden
          dürfen.
        </p>
      </v-col>
    </v-row>
  </v-container>

  <!-- Anwendungsbeispiele -->
  <v-container class="use-cases pl-9 pr-9" fluid>
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <v-icon size="64" color="secondary">mdi-sitemap</v-icon>

        <h2>Anwendungsbeispiele</h2>
      </v-col>
      <!-- Beispiel 1 -->
      <v-col cols="12" md="4" class="d-flex">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column">
          <v-card-item>
            <v-card-title>Schreibe sichere Briefe</v-card-title>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Verfasse Dokumente oder vertrauliche E-Mails, ohne dir Sorgen um Datenschutz machen zu müssen. <span
              class="brand-name">Private Prompts</span> hilft dir dabei, vertrauliche
            E-Mails, Angebote oder Berichte
            zu verfassen, während sensible Informationen geschützt bleiben.
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Beispiel 2 -->
      <v-col cols="12" md="4" class="d-flex">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column">
          <v-card-item>
            <v-card-title>Schutz für Kundendaten</v-card-title>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Verbessere deinen Kundenservice, ohne Kompromisse beim Datenschutz einzugehen. <span
              class="brand-name">Private
              Prompts</span> hilft dir, Kundenanfragen effizient zu bearbeiten und dabei trotzdem die persönlichen
            Daten deiner
            Kunden zu
            schützen.
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Beispiel 3 -->
      <v-col cols="12" md="4" class="d-flex">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column">
          <v-card-item>
            <v-card-title>Texte sicher übersetzen</v-card-title>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Möchtest du wichtige Dokumente oder persönliche Nachrichten übersetzen, ohne sensible Inhalte offenzulegen?
            Mit <span class="brand-name">Private Prompts</span> kannst du Übersetzungsdienste wie <i>Google
              Translate</i> oder <i>deepl.com</i> mit mehr Sicherheit benutzen.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- Feedback und Mitwirkung -->
  <v-container class="feedback" fluid>
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <v-icon size="64" color="secondary">mdi-account-group</v-icon>
        <h2>Mach mit bei Private Prompts</h2>
        <p>
          Deine Meinung zu <span class="brand-name">Private Prompts</span> interessiert mich:
          Teile deine Ideen und Wünsche mit mir, um <span class="brand-name">Private Prompts</span> zu einer Lösung zu
          machen, die viele Menschen nützlich finden. Gemeinsam machen wir die Anwendung besser.
        </p>

        <v-btn color="secondary" href="mailto:frank.boerncke@gmail.com" target="_blank">
          <v-icon left>mdi-message-text-outline</v-icon>&nbsp;Feedback</v-btn>


      </v-col>
    </v-row>
  </v-container>

  <!-- FAQ -->
  <v-container class="frequently-asked-questions pl-9 pr-9" fluid>
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <v-icon size="64" color="secondary">mdi-tooltip-question</v-icon>
        <h2>F.A.Q. - Häufige Fragen und Antworten</h2>
        <v-list lines="three" class="mt-9">
          <v-list-item>
            <v-list-item-title class="faq-title">Warum ist Private Prompts nur auf Englisch
              verfügbar?</v-list-item-title>
            <v-list-item-subtitle class="faq-answer"> "Die Anwendung
              <span class="brand-name">Private Prompts</span> befindet sich noch in der Entwicklung, und in dieser Phase
              ändern sich viele Dinge sehr
              schnell. Um flexibel zu bleiben, ist die Anwendung aktuell nur auf Englisch. Sobald die Entwicklung weiter
              fortgeschritten ist, wird es die Software auch auf Deutsch und in anderen Sprachen geben."


            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="faq-title">Warum gibt es keine Webanwendung von Private Prompts?
            </v-list-item-title>
            <v-list-item-subtitle class="faq-answer">
              "<span class="brand-name">Private Prompts</span> wird für Menschen entwickelt, die ihre Daten schützen und
              nicht an Webdienste
              weitergeben
              möchten. Eine Webanwendung würde diesen Datenschutzgedanken untergraben, da die Verarbeitung von Daten
              dann auf fremden Servern stattfinden würde. Mit Private Prompts bleibt alles auf deinem eigenen Computer –
              sicher und privat."
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>

      </v-col>
    </v-row>
  </v-container>

  <!-- Über mich -->
  <v-container class="about-me" fluid>
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <v-icon size="64" color="secondary">mdi-account</v-icon>
        <h2>Über Frank Börncke</h2>
        <p> Hallo, ich bin Frank Börncke, freiberuflicher Softwareentwickler und Initiator von <span
            class="brand-name">Private Prompts</span>. Mein Ziel ist es, Menschen zu ermöglichen, KI und Webdienste
          sicher und ohne ständige Sorgen um ihre Privatsphäre zu nutzen. </p>
        <p> <span class="brand-name">Private Prompts</span> gibt dir die Kontrolle über deine Daten zurück, damit du
          moderne Technologien mit mehr Vertrauen und Sicherheit nutzen kannst. Deine Privatsphäre bleibt geschützt – du
          entscheidest, was geteilt wird und was nicht. </p>
        <p> Hast du Feedback, Ideen oder möchtest mitwirken? Lass uns gemeinsam eine KI-Anwendung schaffen, die allen
          Nutzerinnen und Nutzern einen echten Mehrwert bietet. Ich freue mich auf den Austausch mit dir! </p>
        <!-- Kontaktmöglichkeiten -->
        <p class="social-links mt-4">
          <v-btn icon color="secondary" href="https://www.boerncke.de/" target="_blank">
            <v-icon size="32">mdi-card-account-phone</v-icon>
          </v-btn>
          <v-btn icon color="secondary" href="mailto:frank.boerncke@gmail.com">
            <v-icon size="32">mdi-email</v-icon>
          </v-btn>
          <v-btn icon color="secondary" href="https://www.linkedin.com/in/frankboerncke/" target="_blank">
            <v-icon size="32">mdi-linkedin</v-icon>
          </v-btn>
          <v-btn icon color="secondary" href="https://x.com/frank_boerncke" target="_blank">
            <v-icon size="32">mdi-twitter</v-icon>
          </v-btn>
          <v-btn icon color="secondary" href="https://github.com/fboerncke/private-prompts-prototype" target="_blank">
            <v-icon size="32">mdi-github</v-icon>
          </v-btn>
        </p>
      </v-col>
    </v-row>
  </v-container>

  <!-- Logo Section -->
  <v-container class="funding" fluid>
    <p>
      Die Entwicklung von <span class="brand-name">Private Prompts</span> wird
      im Zeitraum 1.9.2024-28.02.2025 gefördert durch das <strong>Bundesministerium
        für Bildung und Forschung</strong> und den <strong>Prototype Fund</strong> (Förderkennzeichen 01IS24S44).
    </p>
    <v-row justify="center" class="ml-9 mr-7">
      <v-col cols="12" md="4" class="d-flex justify-center align-center">
        <v-img src="./logos/bmbf_de.jpg" alt="BMBF Logo" contain max-width="30vw" max-height="30vw"></v-img>
      </v-col>
      <v-col cols="12" md="4" class="d-flex justify-center align-center">
        <v-img src="./logos/PrototypeFund_Logo_smallest.svg" alt="Prototype Fund Logo" contain max-width="30vw"
          max-height="30vw"></v-img>
      </v-col>
      <v-col cols="12" md="4" class="d-flex justify-center align-center">
        <v-img src="./logos/logo-okfn.svg" alt="Open Knowledge Foundation Logo" contain max-width="30vw"
          max-height="30vw"></v-img>
      </v-col>
    </v-row>
  </v-container>

  <!-- Footer mit rechtlichen Informationen -->
  <v-footer class="footer-banner">
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="text-center">
          <p>&copy; 2024 Frank Börncke, Private Prompts</p>
          <v-btn color="secondary" text href="https://www.applicate.de/impressum/index.html">Impressum</v-btn>
          &nbsp;
          <v-btn color="secondary" text href="https://www.applicate.de/datenschutz/index.html">Datenschutz</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>

  <!-- Dialog für Video -->
  <v-dialog v-model="videoDialog" max-width="800px">
    <v-card>
      <v-card-text>
        <iframe width="100%" height="450" src="https://app.heygen.com/embeds/f38b706847334f108f42a91b172be357"
          title="Einführungsvideo zu Private Prompts" frameborder="0" allow="encrypted-media; fullscreen;"
          allowfullscreen></iframe>
        <strong>Hinweis</strong>: Die Person in diesem Einführungs-Video ist nicht der Autor von <span
          class="brand-name">Private
          Prompts</span> sondern ein KI-generierter Avatar.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PrivatePromptsMain',
  data() {
    return {
      videoDialog: false,
      hoveredIcon: null,
    };
  },
  methods: {
    playVideo() {
      this.videoDialog = true;
    }
  },
};
</script>

<style scoped>
/* Schriftarten */
body {
  font-family: 'Open Sans', sans-serif;
}

/* Responsive Schriftgrößen */
h1 {
  font-size: clamp(2.5rem, 5vw, 4rem);
}

h2 {
  font-size: clamp(2rem, 4vw, 3rem);
}

h3 {
  font-size: clamp(1.75rem, 3vw, 2.5rem);
}

p,
li {
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 1.5em;
  /* Erhöhter Abstand zwischen Absätzen */
}

/* Begrenzung der Textbreite */
.text-content {
  max-width: 800px;
  margin: 0 auto;
}

/* Markierung von "Private Prompts" */
.brand-name {
  font-weight: bold;
  color: var(--accent-color);
}


/* Hintergrundbild mit Overlay */
.v-img {
  position: relative;
  transition: transform 0.3s ease;
}

.v-img>.v-container {
  position: relative;
  z-index: 1;
}

/* Buttons */
.v-btn {
  border-radius: 25px;
  transition: all 0.3s ease;
  background-color: var(--primary-color);
  text-transform: none;
  /* This prevents text from being converted to uppercase */
}

.v-btn:hover {
  background-color: var(--secondary-color) !important;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

/* Icons */
.v-icon {
  color: var(--primary-color);
}

.v-icon:hover {
  color: var(--secondary-color);
  cursor: pointer;
}

/* Textfarben */
.body {
  background-color: var(--background-color);
  color: var(--text-color);
}

body,
p,
li {
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--secondary-color);
  font-family: 'Montserrat', sans-serif;
}

/* Abschnitte */
.video-section,
.introduction,
.status-message,
.quickstart,
.how-it-works,
.benefits,
.use-cases,
.feedback,
.frequently-asked-questions,
.about-me,
.funding {
  padding: 50px 15px;
}

.video-section {
  background-color: #F1F1F1;
}

.status-message {
  background-color: #F5F5DC;
}

.quickstart {
  background-color: #EAEAEA;
}

.how-it-works {
  background-color: #F5F5DC;
}

.benefits {
  background-color: #EAEAEA;
}

.use-cases {
  background-color: #F5F5DC;
}

.feedback {
  background-color: #EAEAEA;
}

.frequently-asked-questions {
  background-color: #F5F5DC;
}

.about-me {
  background-color: #EAEAEA;
}

.funding {
  background-color: #F5F5DC;
}

/* Karten und Bilder */
.v-card {
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.v-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.v-card-title {
  font-weight: bold;
  color: var(--secondary-color);
}

.v-card-text {
  color: var(--text-color);
  flex-grow: 1;
}


.v-col.d-flex {
  display: flex;
}

.v-img:hover {
  transform: none;
}

/* Social Links */
.social-links {
  padding-bottom: 0px;
}

.social-links .v-btn {
  margin: 0 10px;
}

.social-links .v-icon {
  color: var(--text-color);
}

/* Footer */
.v-footer {
  background-color: var(--secondary-color) !important;
}

.v-footer .white--text {
  color: #ffffff !important;
}

/* Video-Bereich Anpassungen */
.video-card {
  position: relative;
}

.video-banner {
  position: absolute;
  top: 30px;
  right: -100px;
  background-color: rgba(255, 111, 97, 0.9);
  /* Akzentfarbe mit Transparenz */
  color: white;
  padding-left: 100px;
  padding-right: 100px;
  transform: rotate(45deg);
  font-weight: bold;
  z-index: 2;
}

.video-card .v-img {
  position: relative;
}

.video-card .v-img .v-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-card .v-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-card .v-icon {
  font-size: 64px;
}


/* Banner-Stil */
.banner {
  background: linear-gradient(60deg, #3aad9f, #466673);
  padding: 60px 15px;
}


.banner h1 {
  font-size: 3rem;
  color: #092925;
}

.banner h2 {
  font-size: 1.5rem;
  color: #264653;
  margin-bottom: 10px;
}

.banner h3 {
  font-size: 1.4rem;
  color: #264653;
  margin-bottom: 0px;
}

.banner h4 {
  font-size: 1.25rem;
  color: #264653;
  margin-bottom: 10px;
}

.footer-banner {
  background: linear-gradient(60deg, #3aad9f, #466673);
}


/* Carousel Image and Caption */
.carousel-image {
  object-fit: contain;
  width: 80%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.caption {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  color: white;
  padding: 15px;
  border-radius: 5px;
  font-weight: 200;
  margin-bottom: 15px;
}

.caption strong {
  color: var(--accent-color);
}

/* Carousel Item Adjustments */
.v-carousel-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}

.faq-title {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.6;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.faq-answer {
  font-size: 1.125rem !important;
  /* make font italic */
  font-style: italic;
  line-height: 1.5;
  color: var(--text-color);
}

/* Responsive Design for Mobile */
@media (max-width: 600px) {
  .caption {
    font-size: 1rem;
    padding: 5px;
  }
}

@media (max-width: 600px) {
  .banner {
    padding: 40px 15px;
  }
}

@media (min-width: 600px) {

  .how-it-works .v-col,
  .funding .v-col,
  .benefits .v-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>

import { createApp } from 'vue';
import App from './App.vue';
import './assets/styles.css';

// Import Vuetify
import { createVuetify } from 'vuetify';
import 'vuetify/styles'; // Import Vuetify's CSS
import '@mdi/font/css/materialdesignicons.css'; // Import Material Design Icons

// Import Vuetify components
// import * as components from 'vuetify/components';
// import * as directives from 'vuetify/directives';
import { VApp, VMain, VContainer, VRow, VCol, VBtn, VIcon, VImg, VCard, VCardText, VCardItem, VCarousel, VCarouselItem, VFooter, VDialog, VList, VListItem } from 'vuetify/components';
import { Ripple } from 'vuetify/directives';


// Create Vuetify instance
const vuetify = createVuetify({
    components: {
        VApp,
        VMain,
        VContainer,
        VRow,
        VCol,
        VBtn,
        VIcon,
        VImg,
        VCard,
        VCardText,
        VCardItem,
        VCarousel,
        VCarouselItem,
        VFooter,
        VDialog,
        VList,
        VListItem
    },
    directives: {
        Ripple,
    },
    icons: {
        defaultSet: 'mdi',
    },
    theme: {
        defaultTheme: 'light',
    },
});

// Create Vue app
const app = createApp(App);
app.use(vuetify); // Use Vuetify plugin
app.mount('#app');
